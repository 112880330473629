<template>
	<div class="dashboard">
    <div class="dashboard__container">
    	<Everify />
     <!--  <div class="dashboard__container--header pa-0">
        <h1>Jump is an Equal Opportunity Employer</h1>
      </div>
      <div class="dashboard__container--body mb-5" style="width:auto;">
				<p>Jump and it's subsidiaries, including Jump Employee Services LLC, are Equal Opportunity Employers. We do not discriminate on the basis of race, religion, color, sex, gender identity, sexual orientation, age, non-disqualifying physical or mental disability, national origin, veteran status or any other basis covered by appropriate law. All employment is decided on the basis of qualifications, merit, and business need.”</p>
			</div> -->
		</div>
	</div>
</template>


<script>
import Everify from '@/components/Everify.vue'

export default {
  name: 'everify',
  components: {
  	Everify
  }
}
</script>


<Everify />